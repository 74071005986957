<template>
  <div id="NftPool" class="nft_pool_detail">

    <div class="top">
      <div class="left">
        <div class="title">{{$t("nftpool.introduction")}}</div>
        <div class="description">
          <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
            {{ artpoolSimpleInfo.description || artpoolSimpleInfo.descriptionEn }}
          </template>
          <template v-else>
            {{ artpoolSimpleInfo.descriptionEn || artpoolSimpleInfo.description }}
          </template>

          <div class="btn-wrap">
            <div class="btn" @click="onClickGoStakePage">
              {{ $t("nftpool.stakeNft") }}
              <v-icon class="float-right">mdi-chevron-right</v-icon>
            </div>
            <div class="btn" @click="onClickGoVotePage">
              {{ $t("stake.btn.vote") }}
              <v-icon class="float-right">mdi-chevron-right</v-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <img
          v-if="artpoolSimpleInfo.bannerImagePcEnUrl && activeLanguage === 'en'"
          :src="artpoolSimpleInfo.bannerImagePcEnUrl"
        />
        <img
          v-else-if="artpoolSimpleInfo.bannerImagePcUrl && activeLanguage === 'ko'"
          :src="artpoolSimpleInfo.bannerImagePcUrl"
        />
        <img
          v-else
          :src="`/img/threespace/artpool/artpool_round${artpoolSimpleInfo.roundNo}_banner.png`"
        />
      </div>
    </div>

    <!-- artpool gallery tab ====================== -->
    <div class="artpool_gallery_tab_wrap">
      <div
        @click="onClickPlaceTab({idx:0})"
        :class="{active: currentPlace === 0}"
      >{{ $t("stake.nft.tab.total") }}</div>
      <template v-for="(place, i) in artpoolGalleryPlaces">
        <div :key="i" @click="onClickPlaceTab(place)" :class="{active: currentPlace === place.idx}">
          <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
            {{ place.placeName || place.placeNameEn }}
          </template>
          <template v-else>
            {{ place.placeNameEn || place.placeName }}
          </template>
        </div>
      </template>
    </div>

    <!-- artpool gallery list ====================== -->
    <div class="artpool_gallery_list_wrap">
      <template
        class="row"
        v-if="
          artpoolGalleryContent === undefined ||
          artpoolGalleryContent === null ||
          artpoolGalleryContent.length === 0
        "
      >
        <div class="col-12 mb-5 pb-5 text-center" key="0">
          <h5 class="pb-5 my-5 text-gray">
            {{ $t("mypage.noHistoryCollection") }}
          </h5>
        </div>
      </template>
      <div class="gallery-wrapper" v-else>
        <div
          class="position-relative"
          v-for="(item, i) in artpoolGalleryContent"
          :key="i"
          :ref="'cartItem' + item.idx"
        >
          <div
            class="card_list_item cursor-pointer"
            :id="'galleryItem' + item.idx"
            @click="showProductDetail(item)"
          >
            <v-lazy :options="{ threshold: 0.5 }" transition="fade-transition">
              <div
                class="card_media_box"
                @contextmenu.prevent.stop
                :class="{ 'skeleton_display_none': !isBusy }"
              >
                <CardHoverBox
                  :favoritedCnt="item.favoritedCnt"
                  :pageViewCnt="item.product.pageViewCnt"
                  :isExternalCollection="
                      item.idxProduct === undefined ||
                      item.idxProduct === null ||
                      item.idxProduct === ''"
                />
                <h3>
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ item.title || item.titleEn }}
                  </template>
                  <template v-else>
                    {{ item.titleEn || item.title }}
                  </template>
                </h3>
                <template>
                  <template v-if="!item.video">
                    <b-img :src="item.imageUrl"></b-img>
                  </template>
                  <template v-else>
                    <video
                      :poster="replaceDisplayPosterUrl(item)"
                      :src="replaceDisplayCardUrl(item)"
                      type="video/webm"
                      muted
                      loop
                      autoplay
                      playsinline
                    ></video>
                  </template>
                </template>
                <b-skeleton-img
                  no-aspect
                  width="100%"
                  height="100%"
                ></b-skeleton-img>
              </div>
            </v-lazy>
            <div class="card-info">
              <div class="card_title_box" v-if="!isBusy">
                <div
                  class="artist_name"
                  v-if="item.idxProduct !== undefined &&
                      item.idxProduct !== null &&
                      item.idxProduct !== ''"
                >
                  <template v-if="item.product.profileImage === undefined">
                    <CImg
                      class="c-avatar-img mr-2"
                      height="25"
                      src="/img/avatars/profile-gray-person.jpg"
                    />
                  </template>
                  <template v-else>
                    <CImg
                      class="c-avatar-img mr-2"
                      height="25"
                      :src="item.product.profileImageUrl"
                    />
                  </template>
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ item.artist || item.artistEn }}
                  </template>
                  <template v-else>
                    {{ item.artistEn || item.artist }}
                  </template>
                  <CImg
                    src="/img/threespace/icon/ethereum-black.png"
                    v-if="item.nft.network === 'ETHEREUM'"
                    class="chain-logo"
                  />
                  <CImg
                    src="/img/threespace/icon/matic-black.png"
                    v-if="item.nft.network === 'POLYGON'"
                    class="chain-logo"
                  />
                  <CImg
                    src="/img/threespace/icon/kaia-black.png"
                    v-else-if="item.nft.network === 'KLAYTN' || item.nft.network === 'KLIP'"
                    class="chain-logo"
                  />
                  <CImg
                    src="/img/threespace/icon/bitcoin-black.png"
                    v-else-if="item.nft.network === 'ORDINALS'"
                    class="chain-logo"
                  />
                </div>
              </div>
              <div class="card_title_box" v-else>
                <p class="artist_name">
                  <b-skeleton
                    type="avatar"
                    height="30px"
                    width="30px"
                    class="float-left"
                  ></b-skeleton>
                  <b-skeleton class="float-left mt-2 ml-2" width="100px"></b-skeleton>
                </p>
              </div>
              <div class="card_price_box" v-if="!isBusy">
                <div class="text_wrap soldout" v-if="item.owner != undefined">
                  {{ "Owned by @" + item.owner }}
                </div>
                <div class="text_wrap" v-else>
                  <template v-if="item.product.status === 'EXHIBIT'">
                    {{ $t("product.statusExhibit") }}
                  </template>
                  <template
                    v-else-if="
                    activeLanguage !== undefined &&
                    activeLanguage === 'en' &&
                    isCoinPriceNonZero(item.nftType) &&
                    isAcceptableNftType(item.nftType)
                  "
                  >
                    {{ getProductCoinPrice(item.product.productPrice, item.product.nftType) }} {{getCoinCurrencyByNftType(item.product.nftType)}}
                  </template>
                  <template v-else>
                    {{ item.product.productPrice | currency }} {{ item.product.productPrice !== undefined && item.product.productPrice > 0 ? "KRW" : $t("product.statusExhibit") }}
                  </template>
                </div>
                <div class="icon_wrap">
                  <FavoritedButton
                    :favoritedItem="item"
                    @onClickFavoritedButton="onClickFavoritedButton"
                  />
                </div>
              </div>
              <div class="card_price_box" v-else>
              <span>
                <b-skeleton width="50%" height="25px"></b-skeleton>
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <scroll-loader
      :loader-method="requestArtpoolGallery"
      :loader-disable="isLast"
    >
      <svg
        data-v-c2de8a26=""
        viewBox="25 25 50 50"
        class="loader__svg"
        style="width: 50px;"
      >
        <circle
          data-v-c2de8a26=""
          cx="50"
          cy="50"
          r="20"
          class="loader__circle"
          style="stroke: rgb(204, 204, 204);"
        ></circle>
      </svg>
    </scroll-loader>

    <!-- NftPool Gallery Modal -->
    <NftPoolGalleryModal
      ref="nftPoolGalleryModal"
      @onClickPopupNoneSeenWeekBtn="onClickPopupNoneSeenWeekBtn"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import FavoritedButton from "@/views/threespace/components/common/FavoritedButton.vue";
import CardHoverBox from "@/views/threespace/components/common/CardHoverBox.vue";
import NftPoolGalleryModal from '../components/artpool/NftPoolGalleryModal.vue';
import coinUtils from "../../../mixins/CoinUtils";

export default {
  name: "NftPoolGallery",
  components: {
    FavoritedButton,
    CardHoverBox,
    NftPoolGalleryModal
  },
  props: {
    referral: {
      type: String,
      default: ""
    }
  },
  mixins: [coinUtils],
  metaInfo() {
    return {
      title: this.$t("meta.artPool.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.artPool.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.artPool.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ],
    }
  },
  data: function () {
    return {
      isBusy: false,
      perPage: 12,
      currentPage: 0,
      sort: [ { id: "idx", desc: "desc" } ],
      externalParams: {
        keywordPoolType: "NFT",
      },
      idxArtpool: 0,
      currentPlace: 0,
      isLast: true
    }
  },
  computed: {
    ...mapState("artpool", [
      "artpoolItems",
      "artpoolVersionOptions",
      "artpoolSimpleInfo",
      "topRankCollections",
      "artpoolGalleryContent",
      "artpoolGalleryPlaces"
    ]),
    ...mapState({
      me: state => {
        return state.auth.me || {};
      }
    }),
    ...mapGetters("auth", ["isAuthenticated"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
  },
  mounted() {
    this.requestArtpools();

    // 회원가입 레퍼럴
    if (this.referral !== undefined && this.referral !== null && this.referral !== "") {
      this.$log.log("referral: ", this.referral); //TODO
      localStorage.setItem("referral3spaceJoin", this.referral);
    }
  },
  methods: {
    ...mapActions("artpool",[
      "getArtpoolSimple",
      "getArtpoolCollectionsTopRank",
      "getArtpools",
      "getArtpoolGallery",
      "getArtpoolGalleryPlaces"
    ]),
    ...mapActions("favorited", ["addFavorited", "deleteFavorited", "toggleFavorited"]),
    ...mapActions("auth", ["verifyTokenCallback"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    requestArtpool(idxArtpool) {
      this.idxArtpool = idxArtpool;
      this.getArtpoolSimple(this.idxArtpool)
        .then(() => {
          // @NOTE popup 노출 내림
          // if (this.$cookies.get("popupNotSeenForWeekArtpoolGallery") === null) {
          //   if (this.$moment().isAfter(this.artpoolSimpleInfo.voteStartDate)) {
          //     this.$refs["nftPoolGalleryModal"].show();
          //   }
          // }
          this.getArtpoolGalleryPlaces(this.idxArtpool)
            .then(() => {
              this.requestArtpoolGallery();
            })
            .catch(error => {
              this.isBusy = false;
              this.$store.commit("setShowLoading", { show: false, msg: "" });
              this.$log.error(error);
            })
        })
        .catch(error => {
          this.isBusy = false;
          this.$store.commit("setShowLoading", { show: false, msg: "" });
          this.$log.error(error);
        });
    },
    requestArtpools() {
      this.$store.commit("setShowLoading", {show: true, msg: ""});
      this.isBusy = true;
      this.getArtpools(
        this.getPagingParams(
          {
            page: 0,
            pageSize: 12,
            sorted: [ { id: "roundNo", desc: "desc" } ],
            filtered: [],
          },
          this.externalParams
        )
      )
      .then(() => {
        let idxArtpool = 0;
        this.artpoolItems.content.forEach(el => {
          if (el.initActive) {
            idxArtpool = el.idx;
          }
        })
        if (idxArtpool !== 0) {
          this.requestArtpool(idxArtpool);
        }
      })
      .catch(error => {
        this.$log.error(error);
        this.isBusy = false;
        this.$store.commit("setShowLoading", { show: false, msg: "" });
      });
    },
    requestArtpoolGallery() {
      this.getArtpoolGallery(
        this.getPagingParams(
          {
            page: this.currentPage,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          {
            idxArtpool: this.idxArtpool,
            idxArtpoolPlace: this.currentPlace
          }
        ))
        .then(data => {
          this.isBusy = false;
          this.$store.commit("setShowLoading", { show: false, msg: "" });
          this.isLast = data.last;
          this.currentPage = data.pageable.page + 1;
        })
        .catch(error => {
          this.isBusy = false;
          this.$store.commit("setShowLoading", { show: false, msg: "" });
          this.$log.error(error)
        })
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }
      return params;
    },
    onClickGoStakePage() {
      if (this.$moment().isBefore(this.artpoolSimpleInfo.poolStartDate)) {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("stake.notInProgressPool"),
          show: true,
          fontSize: "sm"
        })
      } else {
        this.$router.push({ name: "NftPoolStake", params: { idxArtpool: this.idxArtpool }})
      }
    },
    onClickGoVotePage() {
      if (this.$moment().isBefore(this.artpoolSimpleInfo.voteStartDate)) {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("stake.vote.notInProgress"),
          show: true,
          fontSize: "sm"
        })
      } else {
        this.$router.push({ name: "NftPoolVote", params: { idxArtpool: this.idxArtpool }})
      }
    },
    onClickPlaceTab(item) {
      this.currentPage = 0;
      this.isBusy = true;
      this.$store.commit("setShowLoading", { show: true, msg: "" });
      this.currentPlace = item.idx;
      this.requestArtpoolGallery();
    },
    showProductDetail(item) {
      if (item.idxProduct !== undefined && item.idxProduct !== null && item.idxProduct !== "") {
        this.$router.push("/art/" + item.idxProduct);
      } else {
        if (item.nft.name === "KlipDrops") {
          let url = `https://klipdrops.com/market/${item.nft.contractAddr}/${item.tokenId}`
          window.open(url, "_blank");
        } else {
          let chain = ""
          if (item.nft.rfc.indexOf("ERC") !== -1) {
            chain = "ethereum";
          } else if (item.nft.rfc.indexOf("KIP") !== -1) {
            chain = "klaytn";
          }

          let url = `https://opensea.io/assets/${chain}/${item.nft.contractAddr}/${item.tokenId}`
          window.open(url, "_blank");
        }
      }
    },
    replaceDisplayPosterUrl(item) {
      if (item.idxProduct !== undefined && item.idxProduct !== null && item.idxProduct !== "") {
        if (
          item.product.displayPoster !== undefined &&
          item.product.displayPoster !== null &&
          item.product.displayPoster !== "" &&
          item.product.displayPoster === "THREESPACE"
        ) {
          return item.product.displayPosterUrlThreespace; // 3space S3 URL
        } else {
          if (item.product.displayPosterUrl !== undefined && item.product.displayPosterUrl.startsWith("https://ipfs.io/")) {
            return item.product.displayPosterUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
          }
          return item.product.displayPosterUrl;
        }
      } else {
        return item.imageUrl
      }
    },
    replaceDisplayCardUrl(item) {
      if (item.idxProduct !== undefined && item.idxProduct !== null && item.idxProduct !== "") {
        if (
          item.product.displayCardFile !== undefined &&
          item.product.displayCardFile !== null &&
          item.product.displayCardFile !== "" &&
          item.product.displayCardFile === "THREESPACE"
        ) {
          return item.product.displayCardUrlThreespace; // 3space S3 URL
        } else {
          if (item.product.displayCardUrl !== undefined && item.product.displayCardUrl.startsWith("https://ipfs.io/")) {
            return item.product.displayCardUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
          }
          return item.product.displayCardUrl;
        }
      } else {
        return item.imageUrl
      }
    },
    onClickFavoritedButton(favoritedItem) {
      if (this.isAuthenticated) {
        this.verifyTokenCallback()
          .then(() => {
            if (
              this.me === undefined ||
              this.me === null ||
              this.me.idx === undefined ||
              this.me.idx === null ||
              this.me.idx === ""
            ) {
              // 로그인
              this.hasKlaytnAddr = false;
              this.$store.commit("setCommonAlertModalInfo", {
                msgHtml: this.$t("validation.requiredLogin"),
                show: true,
                fontSize: "sm"
              })
            } else {
              // 로그인 되어있음
              if (favoritedItem.favorited) {
                // true이면 좋아요 헤재
                this.toggleFavorited(favoritedItem);
                this.deleteFavorited({idxCollection: favoritedItem.idx});
              } else {
                // false이면 좋아요
                this.toggleFavorited(favoritedItem);
                this.addFavorited({idxCollection: favoritedItem.idx});
              }
            }
          })
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("validation.requiredLogin"),
          show: true,
          fontSize: "sm"
        })
      }
    },
    onClickPopupNoneSeenWeekBtn() {
      this.$cookies.set("popupNotSeenForWeekArtpoolGallery", "done", "7d");
      this.$refs["nftPoolGalleryModal"].hide();
    },
    onClickExternalLink(externalLink) {
      if (externalLink !== undefined && externalLink !== null && externalLink !== "") {
        if (this.$moment().isBefore(this.artpoolSimpleInfo.poolStartDate)) {
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: "Coming Soon!",
            show: true,
            fontSize: "md"
          })
        } else {
          window.open(externalLink, "_blank");
        }
      } else {
        this.$log.info("External link information does not exist.");
      }
    },
    onClickEventBtn() {
      if (this.$moment().isBefore(this.artpoolSimpleInfo.voteStartDate)) {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("stake.notInProgressPool"),
          show: true,
          fontSize: "sm"
        })
      } else {
        this.$refs["nftPoolGalleryModal"].show();
      }
    },
  },
  filters: {
    decimal: value => {
      if (!value) return 0;
      return (Math.floor((value * 100) / 10 ** 18) / 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    currency: value => {
      if (!value) return "";
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  },
}
</script>
